<button class="btn btn-info m-2" (click)="getMyCurrentPosition()">Get my position</button>
<google-map height="600px" width="100%" #GoogleMap [center]="center" [options]="options" [zoom]="zoom" (mapClick)="click($event)">

    <map-marker 
        *ngFor="let marker of points; let ix = index" 
        [position]="marker.position" [title]="marker.title" 
        [options]="marker.options" 
        (mapClick)="openInfo(ix, marker)" 
        (mapDragend)="onDragend(marker, $event)">
        
        <map-info-window>
            <div class="grid grid-nogutter p-3 text-center" *ngIf="dataContent">
                <div class="col-12">
                    <div class="mb-3">
                        <img class="rounded-circle" style="height: 100px; width: 100px; object-fit: cover;"
                            src="{{dataContent?.photo}}" alt="petprofile">
                    </div>
                </div>
                <div class="col-12">
                    <h5> <i class="fa fa-paw" aria-hidden="true"></i> {{dataContent?.petName}}</h5>
                    <p><span class="fw-bold">Estatus:</span> {{dataContent?.petStatus}}</p> 
                </div>
                <div class="col-12">
                    <button class="btn btn-info" (click)="openWaze(dataContent?.lat, dataContent?.lng)">Waze</button>
                </div>
            </div>
        </map-info-window>
    </map-marker>
</google-map>