<p-table #dt
    [value]="payloadData"
    [columns]="columsData"
    [scrollable]="true" 
    scrollHeight="700px" 
    dataKey="id"
    editMode="row" 
    [rowHover]="false" 
    [rows]="5" 
    [rowsPerPageOptions]="[5,10,25,50]"
    [showCurrentPageReport]="true" 
    [loading]="loading" 
    [paginator]="true" 
    [id]="'userList'"
    responsiveLayout="stack" 
    [tableStyle]="{'min-width': '130rem', 'height': '550px'}"
    currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}" 
    [filterDelay]="0"
    [globalFilterFields]="['id','petName', 'email', 'phone', 'userState', 'isDigitalIdentificationActive']">

    <ng-template pTemplate="caption">
        <div class="grid">
            <div class="d-flex col-12 justify-content-between">
                <button class="btn rounded-2 btn-transparent" (click)="showFilterInputs = !showFilterInputs" ><i class="feather icon-filter "></i>Filtrar</button>
            </div>
        </div>
        <div *ngIf="showFilterInputs" class="grid g-2 my-2">
            <div class="col-12 md:col-3 lg:col-3">
                <input pInputText type="text" id="filterid" (input)="dt.filter($any($event.target) .value, 'id', 'contains')" placeholder="{{'Filter By Id' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-12 md:col-3 lg:col-3">
                <input pInputText type="text" id="filtername" (input)="dt.filter($any($event.target) .value, 'petName', 'contains')" placeholder="{{'Filter By Name' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-12 md:col-3 lg:col-3">
                <input pInputText type="text" id="filteremail" (input)="dt.filter($any($event.target) .value, 'email', 'contains')" placeholder="{{'Filter By Email' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-12 md:col-3 lg:col-3">
                <input pInputText type="text" id="filterphone"  (input)="dt.filter($any($event.target) .value, 'phone', 'contains')" placeholder="{{'Filter By Phone' | translate}}" class="p-column-filter w-100">
            </div>
            <div class="col-12 md:col-3 lg:col-3">
                <p-dropdown 
                    [options]="userTypeFilter" 
                    (onChange)="dt.filter($event.value, 'userState', 'equals')" 
                    [autoDisplayFirst]="false"
                    [showClear]="true"
                    styleClass="p-column-filter d-flex w-100"
                    placeholder="{{'Filter By User Type' | translate}}">
                    <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                    </ng-template>
                    <ng-template let-selectedItem pTemplate="selectedItem">
                        {{selectedItem.label | translate}}
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="col-12 md:col-3 lg:col-3">
                <p-dropdown 
                    [options]="userTypeIdActivateFilter" 
                    (onChange)="dt.filter($event.value, 'isDigitalIdentificationActive', 'equals')" 
                    [autoDisplayFirst]="false"
                    [showClear]="true"
                    styleClass="p-column-filter d-flex w-100"
                    placeholder="{{'Filter By Identification Active' | translate}}">
                    <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                    </ng-template>
                    <ng-template let-selectedItem pTemplate="selectedItem">
                        {{selectedItem.label | translate}}
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 d-none d-lg-flex">
                <p-button (onClick)="refresh()"  styleClass="p-button-secondary mx-1" icon="feather icon-refresh-cw" ngbTooltip="{{'Refresh' | translate }}" label=""></p-button>
                <div *ngIf="payloadData">
                    <p-button [disabled]="payloadData.length == 0" (onClick)="downloadModal()" styleClass="p-button-primary mx-1" icon="feather icon-download" ngbTooltip="{{ 'Download As' | translate }}" label=""></p-button>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th [ngClass]="{'d-none': !col.showInPrimaryHeader}" *ngFor="let col of columns">
                <span *ngIf="col.showInPrimaryHeader"> {{ col.header | translate }} </span>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-payloadData let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
        <tr class="p-selectable-row" [pEditableRow]="payloadData" [ngClass]="{'bg-info': editing && themeSelected == 'theme-default-light', 'bg-dark': editing && themeSelected == 'theme-default-dark', }">
            <td>
                <button [disabled]="!payloadData.newPetProfile" type="button" pButton pRipple [pRowToggler]="payloadData" class="p-button-rounded" [ngClass]="{'p-button-text p-button-success': !payloadData.newPetProfile}" [icon]="expanded ? 'feather icon-chevron-down' : 'feather icon-chevron-right'"></button>
            </td>
            <td>
                <span (click)="copy(payloadData.id)" ngbTooltip="{{payloadData.id}}" class="d-inline-block text-truncate" >{{payloadData.id | translate}}</span>
            </td>
            <!-- <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" id="petName" [(ngModel)]="payloadData.petName">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{payloadData.petName | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td> -->
            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" id="email" [(ngModel)]="payloadData.email">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{payloadData.email | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td>
            <!-- <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" id="phone" [(ngModel)]="payloadData.phone">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{payloadData.phone | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td> -->
            <!-- <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" id="birthDate" [(ngModel)]="payloadData.birthDate">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{payloadData.birthDate | date}}</span>
                    </ng-template>
                </p-cellEditor>
            </td> -->
            <!-- <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" id="ownerPetName" [(ngModel)]="payloadData.ownerPetName">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span ngbTooltip="{{payloadData.ownerPetName}}"  class="d-inline-block text-truncate" style="max-width: 100px;">{{payloadData.ownerPetName | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td> -->
            <!-- <td>
                <span>
                    <button (click)="checkQrCode(payloadData, payloadData._id, ri)" class="btn btn-outline-success btn-sm" > <i class="fas fa-qrcode"></i> </button>    
                </span>
            </td> -->
            <!-- <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" id="petStatus" [(ngModel)]="payloadData.petStatus">
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{payloadData.petStatus | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td> -->

            <td>
                <span>{{transformDate(payloadData.updatedAt)}}</span>
            </td>

            <td>
                <span>{{transformDate(payloadData.createdAt)}}</span>
            </td>

            <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown  
                            styleClass="d-flex w-100" 
                            [options]="userTypeFilter"
                            optionLabel="label"
                            [(ngModel)]="payloadData.userState"
                            optionValue="value">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                            </ng-template>
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.label | translate}}
                            </ng-template>
                        </p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span *ngIf="payloadData.userState === 0" >{{ 'Administrator' | translate}}</span>
                        <span *ngIf="payloadData.userState === 3" >{{ 'Customer' | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td>

            <!-- <td>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-inputSwitch [(ngModel)]="payloadData.isDigitalIdentificationActive"></p-inputSwitch>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span>{{(payloadData.isDigitalIdentificationActive) ? 'Active' : 'Inactive' | translate}}</span>
                    </ng-template>
                </p-cellEditor>
            </td> -->

            <td>
                <div class="d-flex">
                    <!-- <button *ngIf="!editing" type="button" (click)="editPetTest(payloadData)" class="btn btn-icon"> <i class="feather icon-edit"></i> Actualizar</button> -->
                    <button *ngIf="!editing" type="button" (click)="editUser(payloadData)" class="btn btn-icon"> <i class="feather icon-minimize"></i> Sort pets</button>

                    <button *ngIf="!editing" type="button" pInitEditableRow  (click)="onRowEditInit(payloadData)" class="btn btn-icon"> <i class="feather icon-edit"></i> </button>
                    <button *ngIf="editing" type="button" pSaveEditableRow (click)="onRowEditSave(payloadData)" class="btn btn-icon"> <i class="feather icon-save"></i> </button>
                    <button *ngIf="editing" type="button" pCancelEditableRow (click)="onRowEditCancel(payloadData, ri)" class="btn btn-icon"> <i class="feather icon-x"></i> </button>
                    <button *ngIf="!editing" type="button" pCancelEditableRow (click)="deleteUser(payloadData)" class="btn btn-icon"> <i class="feather icon-trash-2"></i> </button>
                    <button *ngIf="!editing" type="button" (click)="addNewPet(payloadData)" class="btn btn-icon"> <i class="feather icon-plus-circle"></i> </button>
                </div>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-payload>
        <tr>
            <td colspan="10">
                <div class="p-3">
                    <p-table 
                        dataKey="idParental" 
                        editMode="row" 
                        [tableStyle]="{'min-width': '130rem'}"
                        [value]="payload.newPetProfile"
                        [columns]="columsData">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th [ngClass]="{'d-none': !col.showInSecondaryHeader}" *ngFor="let col of columns">
                                    <span> {{ col.header | translate }} </span>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-payloadData let-editing="editing" let-ri="rowIndex">
                            <tr class="p-selectable-row" [pEditableRow]="payloadData">
                                <td>
                                    <span>{{payloadData.idParental | translate}}</span>
                                </td>
                                <td>
                                    <span>{{payloadData.petName | translate}}</span>
                                </td>
                                <td>
                                    <span>{{payloadData.email | translate}}</span>
                                </td>
                                <td>
                                    <span>{{payloadData.phone | translate}}</span>
                                </td>
                                <td>
                                    <span>{{payloadData.birthDate | date}}</span>
                                </td>
                                <td>
                                    <span>{{payloadData.ownerPetName | translate}}</span>
                                </td>
                                <td>
                                    <span>
                                        <button (click)="checkQrCode(payloadData, payloadData._id, ri)" class="btn btn-outline-success btn-sm" > <i class="fas fa-qrcode"></i> </button>    
                                    </span>
                                </td>
                                <td>
                                    <button [disabled]="payloadData.petViewCounter.length == 0" (click)="openModalPetViewed(payloadData.petViewCounter)" class="btn btn-outline-danger btn-sm" > <i class="feather icon-map-pin"></i> ({{payloadData.petViewCounter.length}}) </button>
                                </td>
                                <td>
                                    <span>{{payloadData.petStatus | translate}}</span>
                                </td>
                                <td>
                                    <!-- <span>{{(payloadData.isDigitalIdentificationActive) ? 'Active' : 'Inactive' | translate}}</span> -->
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputSwitch [(ngModel)]="payloadData.isDigitalIdentificationActive"></p-inputSwitch>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span>{{(payloadData.isDigitalIdentificationActive) ? 'Active' : 'Inactive' | translate}}</span>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    <button type="button" (click)="deletePetByAdmin(payloadData, payloadData._id, payloadData.idParental)" class="btn btn-icon"> <i class="feather icon-trash-2"></i> </button>
                                    <button *ngIf="!editing" type="button" pInitEditableRow (click)="onRowSecondEditInit(payload, ri)" class="btn btn-icon"> <i class="feather icon-edit"></i> </button>
                                    <button *ngIf="editing" type="button" pSaveEditableRow (click)="onRowEditSaveSecondLevel(payloadData)" class="btn btn-icon"> <i class="feather icon-save"></i> </button>
                                    <button *ngIf="editing" type="button" pCancelEditableRow (click)="onRowEditCancelSecond(payload, ri)" class="btn btn-icon"> <i class="feather icon-x"></i> </button>

                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">There are no order for this product yet.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <tr>
            <td style="padding: 200px; text-align: center;" colspan="12">
                <span *ngIf="!showHardReloadBtn"> {{ 'No data found' | translate }} </span>
                <span *ngIf="showHardReloadBtn"> {{'An error occurred in the process.' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
            </td>
        </tr>
    </ng-template>
</p-table>

<div class="modal fade" id="qrcodeModal" tabindex="-1" aria-labelledby="qrcodeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered modal-search">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="qrcodeModalLabel">{{ 'QR Code' | translate}} de <span *ngIf="itemSelected">{{ itemSelected.petName }}</span></h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div class="modal-body pt-0 text-center">
                <div class="p-4 bg-info my-3 rounded">
                    <span style="cursor: pointer;" (click)="copy(AngularxQrCode)" >{{AngularxQrCode}}</span>
                </div>
                <qrcode [qrdata]="AngularxQrCode" [width]="256" [errorCorrectionLevel]="'H'"></qrcode>
                <qrcode [qrdata]="AngularxQrCode" [width]="256" [errorCorrectionLevel]="'H'" size="500" level="H" canvas="true" #qrCode></qrcode>
                <button class="btn btn-success" (click)="downloadImage(true)">{{'Download Image' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalPetViewed" tabindex="-1" aria-labelledby="modalPetViewedLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered modal-search modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="modalPetViewedLabel">{{ 'Ver Ubicacion' | translate}} </h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div class="modal-body pt-0 text-center">
                <div class="grid">
                    <div *ngFor="let item of itemPetViewed" class="col-12 my-2 border shadow">
                        <span>
                            Fecha: {{ transformDate(item.dateViewed) }}
                        </span>
                        <button class="btn btn-success" (click)="openLocation(item.lat, item.lng)">{{'Ver Ubicacion' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar [(visible)]="sidebarVisible" position="right" [fullScreen]="Media.IsMobile">
    <button (click)="sortPetNameList()" class="btn btn-warning" type="submit">Actualizar Lista</button>
    <div #dropListContainer class="example-container" cdkDropListGroup>
        <div  cdkDropList class="example-list grid" (cdkDropListDropped)="dropImages($event)">
            <div class="col-12 px-3 m-2 example-box" *ngFor="let item of userSelected.newPetProfile let i = index " cdkDrag>
                <div class="d-flex justify-content-between align-items-center example-custom-placeholder rounded-2" *cdkDragPlaceholder>
                    <span class="p-5 fs-5">{{item.petName}}</span>
                    <img [src]="item.photo" style="height: 100px; width: 100px; object-fit: cover;">
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="p-5 fs-5">{{item.petName}}</span>
                    <img [src]="item.photo" style="height: 100px; width: 100px; object-fit: cover;">
                </div>
            </div>
        </div>  
    </div>
</p-sidebar>

<div class="modal fade" id="newPetRegisterByAdmin" tabindex="-1" aria-labelledby="newPetRegisterByAdminLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog modal-dialog-top modal-search modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="newPetRegisterByAdminLabel">{{ 'Register Pet' | translate}} </h5>
                <a (click)="cancel()" type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div *ngIf="itemSelected" class="modal-body">
                <app-general-form 
                    [primaryId]="itemSelected.id"
                    [formIdType]="2" 
                    [isEditProfile]="false"
                    (refreshUserData)="getProfileUpdated($event)"
                ></app-general-form>
            </div>
        </div>
    </div>
</div>