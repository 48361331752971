<div class="login">
    <div class="auth-content text-center">
        <div class="card card-custom mx-3">
            <div class="card-header">
                <img src="assets/images/plaquitascr.png" alt="Plaquitas CR Logo Principal Plaquitas Costa Rica Plaquitas cr" title="Plaquitas CR" class="img-fluid" height="150px" width="150px">
            </div>
            <div class="card-body">
                <form [formGroup]="resetPassword" (ngSubmit)="onSubmit()">
                    <div class="forms-inputs mb-4">
                        <span class="span-login" [ngClass]="{'text-danger': submitted && f.password.errors }">
                            <i class="feather icon-lock"></i> Contraseña
                        </span>
                        <input type="{{showPassword ? 'text': 'password'}}" pInputText class="w-100" formControlName="password" [ngClass]="{ 'is-invalid invalid': submitted && f.password.errors }" />
                        <i style="cursor: pointer; position: relative; top: -37px; float: right;" (click)="showPassword = !showPassword"
                        ngbTooltip="{{!showPassword ? 'Mostrar Contraseña': 'Ocultar Contraseña'}}"
                        class="{{!showPassword ? 'far fa-eye eye-show' : 'far fa-eye-slash eye-hide'}} px-3 py-1"></i>
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <small class="small-msg" *ngIf="f.password.errors.required">Contraseña es requerida</small>
                            <small class="small-msg" *ngIf="f.password.errors?.minlength">Contraseña debe tener al menos 6 caracteres</small>
                        </div>
                    </div>
                
                    <div class="forms-inputs mb-4">
                        <span class="span-login" [ngClass]="{'text-danger': submitted && f.confirmPassword.errors }">
                            <i class="feather icon-lock"></i> Confirmar contraseña
                        </span>
                        <input type="{{showPassword2 ? 'text': 'password'}}" pInputText class="w-100" formControlName="confirmPassword" [ngClass]="{ 'is-invalid invalid': submitted && f.confirmPassword.errors }" />
                        <i style="cursor: pointer; position: relative; top: -37px; float: right;"
                        (click)="showPassword2 = !showPassword2"
                        ngbTooltip="{{!showPassword2 ? 'Mostrar Contraseña': 'Ocultar Contraseña'}}"
                        class="{{!showPassword2 ? 'far fa-eye eye-show' : 'far fa-eye-slash eye-hide'}} px-3 py-1"></i>
                
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <small class="small-msg" *ngIf="f.confirmPassword.errors">Contraseña no son iguales</small>
                            <small class="small-msg" *ngIf="f.confirmPassword.errors.required">Confirmar Contraseña es requerida</small>
                            <small class="small-msg" *ngIf="f.confirmPassword.errors.mustMatch">Contraseña no son iguales</small>
                        </div>
                    </div>
                </form>

                <button [disabled]="loading" type="submit" (click)="onSubmit();" class="btn btn-custom w-100 ">
                    <div *ngIf="loading" class="me-3 spinner-border spinner-border-sm text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> {{'Update Password' | translate}}
                </button>

                <div *ngIf="messageResult != ''" class="alert alert-danger mt-3" role="alert">
                    {{messageResult | translate }}
                </div>

                <div class="col-12 mt-4 d-flex justify-content-between">
                    <!-- <a [routerLink]="['/forgot-pets']" class="text-sm ml-auto text-decoration-none text-dim">{{'Forgot password' | translate}}</a><br> -->
                    <a [routerLink]="['/login']" class="text-sm ml-auto text-decoration-none text-dim">{{'Sign in' | translate}}</a> <br>
                </div>
            </div>
        </div>
    </div>
</div>