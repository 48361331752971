<nav [ngClass]="{'navbar-transparent text-light': isNavbarTransparent, 'bg-secondary text-light': !isNavbarTransparent}"
    class="navbar navbar-expand-lg fixed-top">
    <div class="container" id="pills-tab" role="tablist">
        <a class="navbar-brand" id="inicio" data-bs-toggle="pill" data-bs-target="#pills-inicio" type="button"
            role="tab" aria-controls="pills-inicio" aria-selected="false">
            <img src="assets/images/plaquitascr.png" alt="Plaquitas CR Logo Principal Plaquitas Costa Rica Plaquitas cr"
                title="Plaquitas CR" class="img-fluid" height="80px" width="80px">
        </a>
        <button (click)="showMovileNav()" class="btn btn-light"
            [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent, 'navbar-toggler': !Media.IsMobile}"
            type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item" role="presentation">
                    <a (click)="backToTop()" [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        class="nav-link active" id="inicio" data-bs-toggle="pill" data-bs-target="#pills-inicio"
                        type="button" role="tab" aria-controls="pills-inicio" aria-selected="true">Inicio</a>
                </li>
                <li class="nav-item" role="presentation">
                    <button (click)="backToTop()" [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        class="nav-link" id="nosotros" data-bs-toggle="pill" data-bs-target="#pills-nosotros"
                        type="button" role="tab" aria-controls="pills-nosotros" aria-selected="false">Nosotros</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button (click)="backToTop()" [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        class="nav-link" id="contacto" data-bs-toggle="pill" data-bs-target="#pills-contacto"
                        type="button" role="tab" aria-controls="pills-contacto" aria-selected="false">Contacto</button>
                </li>

                <li class="nav-item" role="presentation">
                    <button (click)="backToTop()" [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        class="nav-link" id="afiliacion" data-bs-toggle="pill" data-bs-target="#pills-afiliacion"
                        type="button" role="tab" aria-controls="pills-afiliacion" aria-selected="false">Afiliación</button>
                </li>
                <ng-container *ngIf="products">
                    <li *ngIf="products.length > 0" class="nav-item" role="presentation">
                        <button (click)="backToTop()" [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                            class="nav-link" id="promociones" data-bs-toggle="pill" data-bs-target="#pills-promociones"
                            type="button" role="tab" aria-controls="pills-promociones" aria-selected="false">Promociones</button>
                    </li> 
                </ng-container>
                <li class="nav-item" role="presentation">
                    <a [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        class="nav-link active" [routerLink]="'/marketplace'" id="marketplace">Marketplace</a>
                </li>
            </ul>
            <ul class="navbar-nav ms-auto ">
                <li class="nav-item active">
                    <a class="nav-link" [routerLink]="'/login'"
                        [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        href="#">Iniciar Sesión</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link" [routerLink]="'/register-pets'"
                        [ngClass]="{'text-light': !isNavbarTransparent, 'text-dark': isNavbarTransparent}"
                        href="#">Registrarme</a>
                </li>
                <li class="nav-item d-flex align-items-center" [ngClass]="{'ms-5': !Media.IsMobile}">
                    <i class="fs-4 feather me-2" [ngClass]="{'icon-moon': showDarkMode , 'icon-sun' : !showDarkMode}"></i>
                    <p-inputSwitch (onChange)="changeTheme($event)" [(ngModel)]="showDarkMode"></p-inputSwitch>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-inicio" role="tabpanel" aria-labelledby="inicio">
        <div style="margin-top: 150px;">
            <div class="container mt-5">
                <div class="row"
                    [ngClass]="{'d-flex flex-column-reverse ': Media.IsMobile, 'd-flex align-items-center': !Media.IsMobile}">
                    <div class="col-md-12 col-lg-6">
                        <div class="text-center bg-body-tertiary rounded-5"
                            [ngClass]="{'p-5': !Media.IsMobile, 'p-1': Media.IsMobile}">
                            <h1 class="text-body-emphasis mt-4">Plaquitas inteligentes para mascotas con código QR</h1>

                            <p class="col-lg-8 mx-auto py-4 text-muted">
                                "Centraliza la información de tu mascota en un perfil digital para asegurar su
                                protección en caso de pérdida."
                            </p>
                            <div class="d-inline-flex gap-2 mb-5">
                                <button
                                    class="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill"
                                    [routerLink]="'/login'" type="button">
                                    Iniciar Sesión
                                </button>
                                <button class="btn btn-outline-secondary btn-lg px-4 rounded-pill"
                                    [routerLink]="'/register-pets'" type="button">
                                    Registrarme
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6" [ngClass]="{'mb-5': Media.IsMobile}">
                        <div class="row g-0">
                            <div class="col-7">
                                <img src="assets/images/iphone.png"
                                    alt="Plaquitas CR Logo Principal Plaquitas Costa Rica Plaquitas cr"
                                    title="Plaquitas CR" class="img-fluid">
                            </div>
                            <div class="col-5">
                                <img src="assets/images/plaquita.png"
                                    alt="Plaquitas CR Logo Principal Plaquitas Costa Rica Plaquitas cr"
                                    title="Plaquitas CR" class="img-fluid img-paw">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Page Content -->
        <section class="py-5">
            <div class="container text-center">
                <div class="row">
                    <div class="col-6">
                        <a href="https://www.facebook.com/profile.php?id=100064041162056" class="nav-link"
                            target="_blank" rel="noopener noreferrer">
                            <i class="feather icon-facebook fs-1"></i>
                        </a>
                    </div>
                    <div class="col-6">
                        <a href="https://www.instagram.com/plaquitas_cr/" class="nav-link" target="_blank"
                            rel="noopener noreferrer">
                            <i class="feather icon-instagram fs-1"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <div class="my-5">
            <div class="p-5 text-center bg-body-tertiary">
                <div class="container py-5">
                    <img src="assets/images/perrito-scan.png" class="dog-paw mb-5" alt="Mascota Plaquitas CR">
                    <p class="col-lg-8 mx-auto lead">
                        Optimiza la seguridad de tu mascota centralizando toda su información en un perfil digital. En
                        caso de extravío, contar con estos datos consolidados garantiza su pronta localización y retorno
                        seguro.
                    </p>
                </div>
            </div>
        </div>

        <div class="d-none">
            <iframe src="https://plaquitascr.com/myPetCode?id=6008be8b89b577251b25e4a6&idSecond=0" frameborder="0"
                class="iframe-pet"></iframe>
        </div>

        <!-- Page Content -->
        <section id="no-enought" class="py-5">
            <div class="container bg-body-tertiary rounded-3 p-3">
                <section>

                    <h2 class="my-5 h3 text-center">No es suficiente?</h2>

                    <!--First row-->
                    <div class="row features-small mb-5 mt-3 wow fadeIn">

                        <!--First column-->
                        <div class="col-md-4">
                            <!--First row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Gratis para uso personal y comercial</h6>
                                    <p class="grey-text">Nuestro sitio es fácil de usar. Con registro gratuito sin
                                        subscripción.
                                    </p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/First row-->

                            <!--Second row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Marketplace</h6>
                                    <p class="grey-text">
                                        Una impresionante colección de productos con la mayor calidad y durabilidad para
                                        el can o familiar.
                                    </p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/Second row-->

                            <!--Third row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Añada permisos</h6>
                                    <p class="grey-text">Al registrar tu can o familiar podrás administrar tu
                                        información personal.</p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/Third row-->

                            <!--Fourth row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Completamente responsivo</h6>
                                    <p class="grey-text">No importa si el sitio se mostrará en una computadora de
                                        escritorio, computadora portátil o tableta
                                        o teléfono móvil. Localpets&family
                                        se ve muy bien en cada pantalla.
                                    <p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/Fourth row-->
                        </div>
                        <!--/First column-->

                        <!--Second column-->
                        <div class="col-md-4 flex-center">
                            <img src="https://i.pinimg.com/564x/08/71/66/087166ec928cb1e2fec2947044cb75ca.jpg"
                                alt="MDB Magazine Template displayed on iPhone"
                                class="z-depth-0 mb-5 img-fluid rounded-3">
                        </div>
                        <!--/Second column-->

                        <!--Third column-->
                        <div class="col-md-4 mt-2">
                            <!--First row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">No necesitas descargar ninguna aplicación</h6>
                                    <p class="grey-text">
                                        La idea principal es no tener que utilizar almacenamiento de las distintas
                                        tiendas (App Store, Play Store)
                                    </p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/First row-->

                            <!--Second row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Que necesitas?</h6>
                                    <p class="grey-text">Lector de QR, son gratuitas y fácil de usar</p>
                                    <p class="grey-text">Algunas cámaras de tu celular tienen lector de QR</p>
                                    <p class="grey-text">Si no tienes puedes descargar uno por medio de la tienda de tu
                                        celular</p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/Second row-->

                            <!--Third row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Como ubicar mi mascota si se pierde?</h6>
                                    <p class="grey-text">
                                        Con nuestras plaquitas intéligentes tu mascota puede llevar tu información de contacto, detalles médicos 
                                        y otros datos importantes para que, en caso de pérdida, pueda ser rápidamente reunida con sus dueños.
                                    </p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/Third row-->

                            <!--Fourth row-->
                            <div class="row">
                                <div class="col-2">
                                    <i class="fas fa-check-circle fa-2x indigo-text"></i>
                                </div>
                                <div class="col-10">
                                    <h6 class="feature-title">Navegadores de mapas disponibles</h6>
                                    <p class="grey-text">
                                        Nuestra aplicación trabaja con mapas de ubicación exacta lo cual puedes obtener mejor información en caso de extravio
                                    </p>
                                    <div style="height:15px"></div>
                                </div>
                            </div>
                            <!--/Fourth row-->
                        </div>
                        <!--/Third column-->

                    </div>
                    <!--/First row-->

                </section>
            </div>
        </section>

        <!-- <header>
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="100000"
                        style="background-image: url('https://res.cloudinary.com/ensamble/image/upload/v1615498557/petbg-01-min_ksil2y_1_50_wta6aw.jpg')">
                        <div class="carousel-caption">
                            <h5>First slide label</h5>
                            <p>Some representative placeholder content for the first slide.</p>
                        </div>
                    </div>
                    <div class="carousel-item"
                        style="background-image: url('assets/images/Plaquitas-Para-Mascotas-1.jpg')">
                        <div class="carousel-caption">
                            <h5>Second slide label</h5>
                            <p>Some representative placeholder content for the second slide.</p>
                        </div>
                    </div>
                    <div class="carousel-item"
                        style="background-image: url('https://res.cloudinary.com/ensamble/image/upload/v1613162479/petbg-03-min_t89guj.jpg')">
                        <div class="carousel-caption">
                            <h5>Third slide label</h5>
                            <p>Some representative placeholder content for the third slide.</p>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </header>

        <div class="container my-5">
            <div class="p-5 text-center bg-body-tertiary rounded-3">
                <div id="carouselExample2" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExample2" data-bs-slide-to="0" class="active"
                            aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExample2" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExample2" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active" data-bs-interval="100000"
                            style="background-image: url('https://res.cloudinary.com/ensamble/image/upload/v1615498557/petbg-01-min_ksil2y_1_50_wta6aw.jpg')">
                            <div class="carousel-caption">
                                <h5>First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div>
                        </div>
                        <div class="carousel-item"
                            style="background-image: url('https://res.cloudinary.com/ensamble/image/upload/v1615497696/second_usjeck.jpg')">
                            <div class="carousel-caption">
                                <h5>Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div>
                        </div>
                        <div class="carousel-item"
                            style="background-image: url('https://res.cloudinary.com/ensamble/image/upload/v1613162479/petbg-03-min_t89guj.jpg')">
                            <div class="carousel-caption">
                                <h5>Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample2"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample2"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div> -->
    </div>
    <div class="tab-pane fade" id="pills-nosotros" role="tabpanel" aria-labelledby="nosotros">
        <div style="margin-top: 150px;">
            <div class="my-5">
                <div class="p-5 text-center bg-body-tertiary">
                    <div class="container py-5">
                        <h1>¿De dónde sale Plaquitas CR?</h1>
                        <img src="assets/images/perrito-scan.png" class="dog-paw mb-5" alt="Mascota Plaquitas CR">
                        <p class="col-lg-8 mx-auto lead">
                            Desde febrero de 2021, en Plaquitas CR trabajamos por el bienestar animal.
                        </p>

                        <p class="col-lg-8 mx-auto lead">
                            Somos una empresa familiar costarricense que nació por una necesidad y con la idea y
                            creatividad de una pareja que vieron un problema y se

                            comprometieron a crear nuevas posibilidades.
                        </p>
                        <p class="col-lg-8 mx-auto lead">
                            Nos tocó ver a nuestras mascotas perderse, así como a las de nuestra gente cercana, y sentir
                            esa mezcla de emociones inexplicable. No podía ser que en 2021 la identificación sea escasa,
                            tediosa y con vencimiento.
                        </p>

                        <p class="col-lg-8 mx-auto lead">
                            Veíamos que una identificación de mascotas más completa y actualizada era posible y más
                            que necesaria. Con la tecnología, podíamos dar una solución, y así fue que empezamos a
                            reunir al pequeño pero gran equipo que, a día de hoy no cambió, y dio todo durante meses

                            para lanzar Plaquitas CR con su primer producto, las Plaquitas Inteligentes.
                        </p>

                        <p class="col-lg-8 mx-auto lead">
                            Gracias a la hermosa comunidad que confió en nosotros, seguimos creciendo y trabajamos
                            con mucho entusiasmo para seguir evolucionando y poder dar más y mejores soluciones
                            tanto a la pérdida de mascotas como a su bienestar en general.
                        </p>
                        <p class="col-lg-8 mx-auto lead">
                            Tenemos muchos desafíos

                            por delante y muchas ganas de superarlos. ¿Nos acompañás?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="pills-contacto" role="tabpanel" aria-labelledby="contacto">
        <div style="margin-top: 150px;">
            <div class="card mx-auto mx-5" style="width: 18rem;" >
                <div class="card-body">
                    <p>Para más información, contáctanos a través del siguiente formulario:</p>
                    <form [formGroup]="aifilateForm" (ngSubmit)="onSubmitContact()" id="contactForm">
                        <div class="form-group my-3"> 
                            <input type="text" placeholder="Nombre completo" formControlName="nombre" class="form-control" id="name"  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"  >
                            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" >
                                <div *ngIf="f.nombre.errors.required">{{'Name is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group my-3"> 
                            <input type="email" placeholder="Correo electrónico" formControlName="email" class="form-control" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback" >
                                <div *ngIf="f.email.errors.required">{{'Email is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group my-3"> 
                            <textarea class="form-control" formControlName="mensaje" placeholder="Contanos qué te gustaría hacer en conjunto" id="message" rows="4" [ngClass]="{ 'is-invalid': submitted && f.mensaje.errors }"></textarea>
                            <div *ngIf="submitted && f.mensaje.errors" class="invalid-feedback" >
                                <div *ngIf="f.mensaje.errors.required">{{'Mensaje is required' | translate}}</div>
                            </div>
                        </div>
                        <button type="submit" (click)="onSubmitContact();" class="btn btn-primary w-100 mt-4">Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade" id="pills-promociones" role="tabpanel" aria-labelledby="promociones">
        <div class="pt-4 text-center" style="margin-top: 150px;">
            <h3>Promociones</h3>
            <div class="my-5"> 
                <div class="text-center bg-body-tertiary" [ngClass]="{'p-5': !Media.IsMobile}">
                    <div *ngIf="products" class="">
                        <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="true" [responsiveOptions]="responsiveOptions">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-item-content mt-4">
                                        <p-tag styleClass="ptag-severity" [value]="TranslateText(product.inventoryStatus)" [severity]="getSeverity(product.inventoryStatus)"></p-tag>
                                        <div class="mb-3" *ngIf="product.images.length > 0">
                                            <img class="rounded-5 catalog-image" src="{{product.images[0].imageURL}}" [alt]="product.name"/>
                                        </div>
                                        <div>
                                            <h4 class="mb-1">{{ product.productName }}</h4>
                                            <h6 class="mt-0 mb-3">{{ product.price | currency:'CRC':'symbol':'1.0-0' }}</h6>
                                            <p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
                                            <div class="mt-5 flex align-items-center justify-content-center gap-2">
                                                <p-button icon="feather icon-star" />
                                                <p-button icon="feather icon-maximize-2" severity="secondary" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="tab-pane fade" id="pills-afiliacion" role="tabpanel" aria-labelledby="afiliacion">
        <div style="margin-top: 150px;">
            <div class="card mx-auto mx-5" style="width: 18rem;" >
                <div class="card-body">
                    <p>Si estás interesado en ser parte de este equipo, contáctanos a través del siguiente formulario:</p>
                    <form [formGroup]="aifilateForm" (ngSubmit)="onSubmitAfiliados()" id="contactForm">
                        <div class="form-group my-3"> 
                            <input type="text" placeholder="Nombre completo" formControlName="nombre" class="form-control" id="name"  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }"  >
                            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" >
                                <div *ngIf="f.nombre.errors.required">{{'Name is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group my-3"> 
                            <input type="email" placeholder="Correo electrónico" formControlName="email" class="form-control" id="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback" >
                                <div *ngIf="f.email.errors.required">{{'Email is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group my-3"> 
                            <textarea class="form-control" formControlName="mensaje" placeholder="Contanos qué te gustaría hacer en conjunto" id="message" rows="4" [ngClass]="{ 'is-invalid': submitted && f.mensaje.errors }"></textarea>
                            <div *ngIf="submitted && f.mensaje.errors" class="invalid-feedback" >
                                <div *ngIf="f.mensaje.errors.required">{{'Mensaje is required' | translate}}</div>
                            </div>
                        </div>
                        <button type="submit" (click)="onSubmitAfiliados();" class="btn btn-primary w-100 mt-4">Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<footer class="text-center text-lg-start bg-body-tertiary text-muted">
   
    <!-- Section: Social media -->
  
    <!-- Section: Links  -->
    <section class="py-5">
        <div class="container text-center text-md-start mt-5">
            <!-- Grid row -->
            <div class="grid mt-3"> 
                <div class="col-12 md:col-4 lg:col-4 mx-auto mb-4">
                    <!-- Content -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        <i class="fas fa-paw me-3"></i> Plaquitas CR
                    </h6>
                    <p>
                        Centraliza la información de tu mascota en un perfil digital para asegurar su protección en caso de pérdida.
                    </p>
                </div> 
 
                <div class="col-12 md:col-4 lg:col-4 mx-auto mb-4 px-5">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        Useful links
                    </h6>
                    <p>
                        <a [routerLink]="'/login'" class="text-reset">Iniciar Sesión</a>
                    </p>
                    <p>
                        <a [routerLink]="'/register-pets'" class="text-reset">Registrarme</a>
                    </p>
                    <p>
                        <a [routerLink]="'/privacy-policy'" class="text-reset">Politica de privacidad</a>
                    </p>
                    <p>
                        <a [routerLink]="'/terms-and-condition'" class="text-reset">Terminos y condiciones</a>
                    </p>
                </div> 
        
                <!-- Grid column -->
                <div class="col-12 md:col-4 lg:col-4 mx-auto mb-md-0 mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">Contacto</h6>
                        <p><i class="fas fa-home me-3"></i> Desamparados, San José 10307, Costa Rica</p>
                    <p>
                        <i class="fas fa-envelope me-3"></i>
                        soporte@localpetsandfamily.com
                    </p>
                    <p><i class="fas fa-phone me-3"></i> + 506 7016-0434</p>
                    <p><i class="fas fa-phone me-3"></i> + 506 8595-9491</p>
                </div> 
            </div> 
        </div>
    </section>
    <!-- Section: Links  -->
  
    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
        © {{year}} Copyright 
        <a class="text-reset fw-bold" href="https://plaquitascr.com/">Plaquitas CR</a>
    </div>
    <!-- Copyright -->
</footer>
  <!-- Footer -->