
    <ng-container *ngIf="locationPermissionDenied; else elseTemplate">
        <div class="container d-grid text-center justify-content-center p-5">
            <p class="notfound-title">{{ 'Enable your location' | translate }}</p>
            <div class="mx-auto notfound-content">
                <img class="w-100" src="assets/images/location-permission-remove.png" alt="Allow access permission">
                <p class="p-2 notfound-text my-4">
                    {{ 'Permission Location' | translate }} {{payloadData.petName}} {{'Allow permission' | translate}}
                </p>
                <button (click)="openChromeSettings()" style="width: 200px;" class="btn btn-custom mt-5 text-uppercase"> {{ 'Enable location' | translate }}</button>
            </div>
        </div> 
    </ng-container>
    <ng-template #elseTemplate>
        <div *ngIf="payloadData" class="container-fluid" [ngClass]="{'p-0 px-0': Media.IsMobile}">
            <div class="row" [ngClass]="{'g-0': Media.IsMobile}">
                <div class="col-12 col-md-7 col-lg-7 col-xl-5 d-flex justify-content-center p-0">
                    <div class="card-profile public mb-4 text-center" [ngClass]="{'w-100': Media.IsMobile}">
                        <div class="banner">
                            <img data-bs-toggle="modal" data-bs-target="#openPhotoProfileModal" class="rounded-circle" style="object-fit: cover; height: 10rem; width: 10rem;" src="{{payloadData.photo}}" alt="{{payloadData.petName}}">
                        </div>
                        <div class="menu">
                            <div class="dropdown">
                                <div class="opener" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="dropdown-menu dropdown-menu-bg test" aria-labelledby="dropdownMenuButton">
                                    <a (click)="copy()" class="dropdown-item p-2" style="cursor: pointer;"><i class="feather icon-external-link mt-1 mx-1 fs-5"></i> Copiar enlace</a>
                                    <div class="dropdown-item d-flex align-items-center">
                                        <i class="fs-4 feather me-2" [ngClass]="{'icon-moon': showDarkMode , 'icon-sun' : !showDarkMode}"></i>
                                        <p-inputSwitch (onChange)="changeTheme($event)" [(ngModel)]="showDarkMode"></p-inputSwitch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="fs-4 text-primary">{{'Hola Soy' | translate}}</span>
                
                        <span class="fs-4 text-primary">{{payloadData.petName}}</span>
                
                        <span class="fw-bold btn-secondary p-3 mx-5 my-3">
                            <a href="https://wa.me/+506{{removeRegex(payloadData.phone)}}" class="nav-link" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                </svg>
                                Enviar Whatsapp
                            </a>
                        </span> 
                    </div>
                </div>
                <div class="col-12 col-md-5 col-lg-5 col-xl-6 mb-5" [ngClass]="{'px-2': Media.IsMobile}">
                <p-card header="{{'Perfil de ' + payloadData.petName }}" styleClass="card-custom">
                    
                        <div class="px-3">
                            <div *ngIf="payloadData.race && payloadData.race != ''" class="forms-inputs">
                                <h5 class="fw-bold">{{ 'Race' | translate }} </h5>
                                <span>{{payloadData.race}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.weight && payloadData.weight != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{ 'Weight' | translate }} </h5>
                                <span>{{payloadData.weight}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.birthDate && payloadData.birthDate != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{ 'Birthdate' | translate }} </h5>
                                <span>{{payloadData.birthDate | date }} | {{ payloadData.petName }} {{'have' | translate}}
                                    {{calculateAge(payloadData.birthDate)}} {{'years' | translate }} humanos y en años canino aproximadamente:
                                    {{calcularEdadPerroDesdeHumano()}} </span>
                            </div>
                        
                            <div *ngIf="payloadData.ownerPetName && payloadData.ownerPetName != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold"> {{ 'Owner Father Name' | translate }} </h5>
                                <span>{{payloadData.ownerPetName}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.phone && payloadData.phone != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{'Phone Number' | translate}} </h5>
                                <span>{{payloadData.phone}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.veterinarianContact && payloadData.veterinarianContact != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{ 'Veterinarian Name' | translate }} </h5>
                                <span>{{payloadData.veterinarianContact}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.phoneVeterinarian && payloadData.phoneVeterinarian != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{'Phone Veterinarian' | translate}} </h5>
                                <span>{{payloadData.phoneVeterinarian}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.favoriteActivities && payloadData.favoriteActivities != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{ 'Favorite Activities' | translate }} </h5>
                                <span>{{payloadData.favoriteActivities}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.healthAndRequirements && payloadData.healthAndRequirements != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{ 'Health and Requirements' | translate }} </h5>
                                <span>{{payloadData.healthAndRequirements}}</span>
                            </div>
                        
                            <div *ngIf="payloadData.address && payloadData.address != ''" class="forms-inputs mt-4">
                                <h5 class="fw-bold">{{ 'Address' | translate }} </h5>
                                <span>{{payloadData.address}}</span>
                            </div>
                        
                        
                            <div class="w-100 text-center">
                                <qrcode [qrdata]="AngularxQrCode" size="200" [allowEmptyString]="true" [width]="156"
                                    [errorCorrectionLevel]="'L'"></qrcode>
                            </div>
                        </div>
                </p-card>
                </div>
            </div>
        </div>
    </ng-template>

       <!-- Page Content -->
<section class="py-1">
    <div class="container text-center">
        <div class="grid">
            <div class="col-4">
                <a class="nav-link text-custom"
                    [routerLink]="'/inicio'" rel="noopener noreferrer">
                    <i class="feather icon-home fs-1"></i>
                </a>
            </div>
            <div class="col-4">
                <a href="https://www.facebook.com/profile.php?id=100064041162056" class="nav-link text-custom"
                    target="_blank" rel="noopener noreferrer">
                    <i class="feather icon-facebook fs-1"></i>
                </a>
            </div>
            <div class="col-4">
                <a href="https://www.instagram.com/plaquitas_cr/" class="nav-link text-custom" target="_blank"
                    rel="noopener noreferrer">
                    <i class="feather icon-instagram fs-1"></i>
                </a>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="openPhotoProfileModal" tabindex="-1" aria-labelledby="openPhotoProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-top modal-search">
        <div class="modal-content" style="background-color: transparent; border: none;">
            <div class="modal-header px-3 py-2 justify-content-end" style="background-color: transparent; border: none;">
                <a type="button" class="modal-title bg-light" style="    border-radius: 50px;
                width: 27px;
                height: 27px;
                text-align: center;" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div *ngIf="payloadData" class="modal-body">
                <img class="w-100" style="object-fit: cover;" src="{{payloadData.photo}}" alt="{{payloadData.photo}}">
            </div>
        </div>
    </div>
</div>