<div *ngIf="payloadData" class="flex align-items-start flex-wrap" style="min-height: 200px">
    <div class="card flex align-items-center justify-content-center font-bold border-round m-2 p-5" style="min-width: 200px; min-height: 150px">
        <div class="mb-0 text-center">
            <p>{{'Total Registrados' | translate}} | {{payloadData.newPetProfile.length }}</p>
            <button (click)="registerNewPet()" class="btn btn-secondary">
                <i class="fa fa-paw mt-1 mx-1"></i> Añadir Mascota
            </button>
        </div>
    </div>
    <div class="card flex align-items-center justify-content-center font-bold border-round m-2 p-5" style="min-width: 200px; min-height: 150px">
        <div class="mb-0 text-center">
            <p *ngIf="payloadData.updatedAt" class="fw-bold">
                {{'Last Modification' | translate}} {{transformDate(payloadData.updatedAt)}}
            </p> 
            <p *ngIf="payloadData.createdAt" class="fw-bold">
                {{'Fecha creación' | translate}} {{transformDate(payloadData.createdAt)}}
            </p> 
        </div>
    </div> 
</div>

<div *ngIf="payloadData">

    <ng-container *ngIf="payloadData.newPetProfile.length > 0; else elseTemplate">
        <p-card header="Mi lista de mascotas" class="card mt-3">
            <p-dataView 
                #dv 
                filterBy="petName"
                [value]="payloadData.newPetProfile" 
                title="Mi lista de mascotas"  
                dataKey="id"  
                [layout]="'list'"
                [showCurrentPageReport]="true"> 
                <p-header> 
                    <div class="grid">
                        <div class="d-flex col-12 justify-content-between">
                            <button class="btn btn-transparent rounded-2" (click)="showFilterInputs = !showFilterInputs" ><i class="feather icon-filter "></i>Filtrar</button>
                            <div *ngIf="!Media.IsMobile && showFilterInputs" class="col-12 md:col-3 lg:col-3">
                                <input type="search" class="w-100" pInputText placeholder="Buscar por nombre" (input)="Myfilter($any($event.target) .value)">
                            </div>
                           
                            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                        </div>
                        <div *ngIf="Media.IsMobile && showFilterInputs" class="col-12">
                            <div class="col-12 md:col-4 lg:col-4">
                                <input type="search" class="w-100" pInputText placeholder="Buscar por nombre" (input)="Myfilter($any($event.target) .value)">
                            </div> 
                        </div>
                    </div>
                </p-header>
    
                <ng-template let-item pTemplate="listItem" let-index="rowIndex">
                    <div class="col-12 md:col-6 lg:col-6 p-2">
                        <div class="p-2 pb-0 border-0 surface-border surface-card rounded-2 shadow"> 
                            <div class="grid">
                                <div class="col-7 p-0" style="position: relative;">
                                    <img (click)="OpenProfilePhoto(item, false, payloadData._id, item._id)" class="rounded-2" style="object-fit: cover; height: 250px; width: 100%;" src="{{item.photo}}" [alt]="item.petName" />
                                    <button style="position: absolute; top: 85%; left: 0%;" (click)="OpenProfilePhoto(item, true, payloadData._id, item._id)" class="btn btn-warning btn-sm w-100 p-2"> <i class="feather icon-camera"></i> Cambiar foto </button>
                                </div>
                                <div class="col-5">
                                    <div class="grid mt-2">
                                        <div class="col-12 text-center p-0">
                                            <span style="font-size: 10px;" class="text-dim">Perfil visto: ({{item.petViewCounter.length }})</span>
                                        </div>
                                        <div class="col-12">
                                            <div (click)="editProfile(payloadData._id, index)"  style="cursor: pointer;" class="font-bold text-center" [ngClass]="{'text-truncate': Media.IsMobile}">
                                                <span class="font-semibold" style="font-size: 15px;"> 
                                                    {{ item.petName }}
                                                </span> 
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button (click)="openExternalProfile(payloadData._id, index)" type="submit" class="btn btn-primary btn-sm w-100 text-center">
                                                 <i class="feather icon-external-link"></i> Abrir
                                            </button> 
                                        </div>
                                        <div class="col-12">
                                            <button (click)="copy(payloadData._id, index)" type="submit" class="btn btn-info btn-sm w-100 text-center">
                                                 <i class="feather icon-copy"></i> Copiar
                                            </button> 
                                        </div>
                                        <div class="col-12">
                                            <button (click)="editProfile(payloadData._id, index)" type="submit" class="btn btn-secondary btn-sm w-100 text-center ">
                                                 <i class="feather icon-edit"></i> Editar Perfil
                                            </button> 
                                        </div>
                                        <div class="col-12">
                                            <button (click)="deletePet(item)" type="submit" class="btn btn-danger btn-sm w-100 text-center">
                                                 <i class="feather icon-trash"></i> Eliminar
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template let-item pTemplate="gridItem" let-index="rowIndex">
                    <div class="col-6 md:col-6 lg:col-3 px-1">
                        <div class="border-1 surface-border surface-card rounded-2 shadow p-2"> 
                            <div style="position: relative;">
                                <img (click)="OpenProfilePhoto(item, false, payloadData._id, item._id)" class="rounded-2" style="object-fit: cover; height: 260px; width: 100%;" src="{{item.photo}}" [alt]="item.petName" />
                                <button style="position: absolute; top: 85%; left: 0;" (click)="OpenProfilePhoto(item, true, payloadData._id, item._id)" class="btn btn-warning btn-sm w-100 p-2"> <i class="feather icon-camera"></i> Cambiar foto </button>
                            </div>
                            
                            <div (click)="editProfile(payloadData._id, index)"  style="cursor: pointer;" class="font-bold text-center" [ngClass]="{'text-truncate': Media.IsMobile}">
                                <span class="font-semibold" style="font-size: 15px;"> 
                                    {{ item.petName }}
                                </span> 
                            </div>
                            
                            <div class="grid mt-2">
                                <div class="col-3 text-center">
                                    <button (click)="openExternalProfile(payloadData._id, index)" type="submit" class="btn btn-primary btn-sm">
                                         <i class="feather icon-external-link"></i>
                                    </button> 
                                </div>
                                <div class="col-3 text-center">
                                    <button (click)="editProfile(payloadData._id, index)" type="submit" class="btn btn-secondary btn-sm">
                                         <i class="feather icon-edit"></i>
                                    </button> 
                                </div>
                                <div class="col-3 text-center">
                                    <button (click)="deletePet(item)" type="submit" class="btn btn-danger btn-sm">
                                         <i class="feather icon-trash"></i>
                                    </button> 
                                </div>
                                <div class="col-3 text-center">
                                    <button (click)="copy(payloadData._id, index)" type="submit" class="btn btn-info btn-sm">
                                         <i class="feather icon-copy"></i>
                                    </button> 
                                </div>
                            </div>
                            <div class="col-12 text-center p-0">
                                <span style="font-size: 10px;" class="text-dim">Perfil visto: ({{item.petViewCounter.length }})</span>
                            </div>
                            
                        </div>
                    </div>
                </ng-template>
                
                <ng-template pTemplate="empty">
                    <span *ngIf="!showHardReloadBtn"> {{ 'No data found' | translate }} </span>
                    <span *ngIf="showHardReloadBtn"> {{'An error occurred in the process.' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                    <img class="w-100" style="height: 400px; object-fit: cover;" src="assets/images/gifts/gift-version-{{randomNumber}}.gif" alt="Not Found Image">
                </ng-template>
            </p-dataView>  
        </p-card>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="container">
            <div class="grid text-center">
                <div class="col-12">
                    <img [ngClass]="{'w-100' : Media.IsMobile}" src="assets/images/mascota.png" alt="Mascota No Registrada">
                </div>
                <div class="col-12">
                    <span>Vaya.. parece que no tienes ninguna mascota registrada en ese caso puedes registrarlo aqui</span>
                </div>
                <div class="col-12">
                    <button (click)="registerNewPet()" class="btn btn-secondary ms-4">
                        <i class="fa fa-paw mt-1 mx-1"></i> Añadir Mascota
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>


<div class="modal fade" id="registerModal" tabindex="-1" aria-labelledby="registerModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog modal-dialog-top modal-search modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="registerModalLabel">{{ 'Register Pet' | translate}} </h5>
                <a (click)="cancel()" type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div *ngIf="showRegisterModal" class="modal-body">
                <app-general-form 
                    [primaryId]="payloadData._id"
                    [formIdType]="2" 
                    [isEditProfile]="false"
                    (refreshUserData)="getProfileUpdated($event)"
                ></app-general-form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog modal-dialog-top modal-search">
        <div class="modal-content" *ngIf="itemSelected">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="editProfileModalLabel">{{ 'Edit Profile' | translate}}:  {{itemSelected.petName}}</h5>
                <a (click)="cancel()" type="button" class="modal-title px-0"  aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div class="modal-body pt-0"> 
                <app-general-form 
                    [itemSelected]="itemSelected"
                    [primaryId]="payloadData._id"
                    [secondaryId]="itemSelected._id"
                    [formIdType]="3" 
                    [isEditProfile]="true"
                    (refreshUserData)="getProfileUpdated($event)"
                ></app-general-form>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="openPhotoProfileModal" tabindex="-1" aria-labelledby="openPhotoProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-top modal-search modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header px-3 py-2 justify-content-end">
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div *ngIf="payloadData" class="modal-body">
                <ng-container *ngIf="isEditProfilePhoto; else elseTemplate">
                    <p-fileUpload name="myfile[]" [maxFileSize]="maxSizeInBytes" (onSelect)="processFile($event)" [multiple]="false" [showUploadButton]="false"
                        [chooseLabel]="(Media.IsMobile) ? 'Subir' : 'Subir Imagen'" [chooseIcon]="'feather icon-image'"  invalidFileSizeMessageDetail="El archivo excede el tamaño máximo permitido de 6MB"
                        [chooseStyleClass]="'p-1'"  allowTypes="*.jpg;*.png;*.jpeg;" [cancelLabel]="'Cancelar'" [cancelStyleClass]="'p-1'" accept="image/*">

                        <ng-template pTemplate="toolbar">
                            <div *ngIf="!Media.IsMobile" class="py-3">Arrastra y suelta la imagen a esta zona <i class="feather icon-arrow-down"></i> </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFiles.length">
                                    <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                                </ul>
                            </ng-template>
                        </ng-template>
                    </p-fileUpload>
                    <div class="w-100 text-center pt-3">
                        <button class="btn btn-outline-success" (click)="saveProfilePhoto()">
                            <i class="feather icon-save"> </i> <span class="ps-3">{{'Edit Profile' | translate }}</span>
                        </button>    
                    </div>

                </ng-container>
                <ng-template #elseTemplate>
                    <img *ngIf="itemSelected" class="w-100" src="{{itemSelected.photo}}" alt="Photo Profile" srcset="">
                </ng-template>
            </div>
        </div>
    </div>
</div>