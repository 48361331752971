<ng-container *ngIf="locationPermissionDenied; else elseTemplate">
    <div class="container d-grid text-center justify-content-center p-5">
        <p class="notfound-title">{{ 'Enable your location' | translate }}</p>
        <div class="mx-auto notfound-content">
            <img class="w-100" src="assets/images/location-permission-remove.png" alt="Allow access permission">
            <p class="p-2 notfound-text my-4">
                {{ 'Permission Location' | translate }} {{payloadData.petName}} {{'Allow permission' | translate}}
            </p>
            <button (click)="openChromeSettings()" style="width: 200px;" class="btn btn-custom mt-5 text-uppercase"> {{ 'Enable location' | translate }}</button>
        </div>
    </div> 
</ng-container>
<ng-template #elseTemplate>
    <div *ngIf="payloadData" class="container-fluid pt-4">
        <div class="grid g-2">
            <div class="col-12 d-flex justify-content-center ">
                <div class="img-custom p-3 card-custom card-identity">
                    <div class="row g-3">
                        <div class="col-1 col-md-1 col-lg-1" [ngClass]="{'mb-3': Media.IsMobile}">
                            <img height="20px" width="25px" src="assets/images/flag-costa-rica.png" alt="CostaricaFlag" srcset="">
                        </div>
                        <div class="col-7 col-md-5 col-lg-7 text-end">
                            <div class="d-grid">
                                <span style="font-family: 'Times New Roman', Times, serif;" [ngStyle]="{'font-size': Media.IsMobile ? '14px' : ''}">REPÚBLICA DE COSTA RICA</span>
                                <small class="fw-bold" [ngStyle]="{'font-size': Media.IsMobile ? '12px' : ''}">Tribunal Supremo Canino</small>
                                <small class="fw-bold" [ngStyle]="{'font-size': Media.IsMobile ? '12px' : ''}" [ngClass]="{'pb-5': !Media.IsMobile, 'pb-2': Media.IsMobile}">Cédula de identidad</small>
                            </div>
    
                            <span *ngIf="Media.IsMobile" class="mt-2 fs-6 fw-bold d-inline-block text-truncate" style="max-width: 100px;">{{payloadData._id}}</span>
                            <span *ngIf="!Media.IsMobile" class="mt-2 fs-6 fw-bold  d-inline-block text-truncate" style="max-width: 150px;">{{payloadData._id}}</span>
                            <div class="text-end">
                                <img height="100" width="100" style="opacity: 0.4;" src="assets/images/paw-cedula-identidad.png" alt="" srcset="">
                            </div>
                        </div>
                        <div class="col-4 col-md-6 col-lg-4 text-end" style="padding: 0px 0px 0px 0px;">
                            <div class="banner">
                                <img class="rounded-custom image-profile" style="object-fit: cover;" src="{{payloadData.photo}}" alt="{{payloadData.photo}}">
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex align-items-center">
                        <div class="col-1">
                            <img height="50" width="50" class="rounded-circle" style="object-fit: cover; opacity: 0.2;" src="{{payloadData.photo}}" alt="{{payloadData.photo}}">
                        </div>
                        <div class="col-8">
                            <div class="row g-2">
                                <div class="col-6 text-end">
                                    <small>Nombre:</small>
                                </div>
                                <div class="col-6">
                                    <span class="fw-bold">{{payloadData.petName}}</span>
                                </div>
                                <div class="col-6 text-end">
                                    <small>Teléfono:</small>
                                </div>
                                <div class="col-6">
                                    <span class="fw-bold">{{payloadData.phone}}</span>
                                </div>
                            </div> 
                        </div>
                        <div class="col-3 text-end">
                            <img height="20px" width="25px" style="opacity: 0.2;" src="assets/images/flag-costa-rica.png" alt="CostaricaFlag" srcset="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <div class="img-custom p-3 card-custom card-identity">
                    <div class="row d-flex align-items-center">
                        <div class="col-12 text-end d-grid">
                            <div class="row g-2 d-flex align-items-center">
                                <div class="col-5 col-md-4 col-lg-4 text-end">
                                    <small>Número de cédula:</small><br>
                                </div>
                                <div class="col-7 col-md-8 col-lg-8 text-start">
                                    <span class="fw-bold d-inline-block text-truncate" style="max-width: 150px;">{{payloadData._id}}</span><br>
                                </div>
    
                                <ng-container *ngIf="payloadData.birthDate && payloadData.birthDate != ''">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Fecha de Nacimiento:</small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{transformDate(payloadData.birthDate) }}</span><br>
                                    </div>
                                </ng-container>
                                
                                <ng-container *ngIf="payloadData.ownerPetName && payloadData.ownerPetName != ''">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Nombre del padre o madre:</small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{ payloadData.ownerPetName }}</span><br>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngIf="payloadData.veterinarianContact && payloadData.phoneVeterinarian">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Contacto de Veterinaria:</small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{ payloadData.veterinarianContact }} al ( {{payloadData.phoneVeterinarian}} )</span><br>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngIf="payloadData.favoriteActivities && payloadData.favoriteActivities != ''">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Actividades Favoritas: </small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{ payloadData.favoriteActivities }}</span><br>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngIf="payloadData.favoriteActivities && payloadData.favoriteActivities != ''">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Salud y requerimientos: </small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{ payloadData.healthAndRequirements }}</span><br>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngIf="payloadData.address && payloadData.address != ''">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Direccion:</small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{ payloadData.address }}</span><br>
                                    </div>
                                </ng-container>
    
                                <ng-container *ngIf="payloadData.genderSelected && payloadData.genderSelected != ''">
                                    <div class="col-5 col-md-4 col-lg-4 text-end">
                                        <small>Sexo:</small><br>
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-8 text-start">
                                        <span class="fw-bold">{{ (payloadData.genderSelected == '2')? 'H': 'M' }}</span><br>
                                    </div>
                                </ng-container>
                            </div> 
                        </div>
                    </div>
                    <div class="row text-center">
                        <qrcode [qrdata]="AngularxQrCode" size="200" [allowEmptyString]="true" [width]="156" [errorCorrectionLevel]="'L'"></qrcode>
                    </div>
                    <div class="row d-flex align-items-center">
                        <div class="col-1">
                            <img height="50" width="50" class="rounded-circle" style="object-fit: cover; opacity: 0.2;" src="{{payloadData.photo}}" alt="{{payloadData.photo}}">
                        </div>
                        <div class="col-11 text-end">
                            <img height="20px" width="25px" style="opacity: 0.2;" src="assets/images/flag-costa-rica.png" alt="CostaricaFlag" srcset="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</ng-template>

<section class="py-1">
    <div class="container text-center">
        <div class="grid">
            <div class="col-4">
                <a class="nav-link"
                    [routerLink]="'/inicio'" rel="noopener noreferrer">
                    <i class="feather icon-home fs-1"></i>
                </a>
            </div>
            <div class="col-4">
                <a href="https://www.facebook.com/profile.php?id=100064041162056" class="nav-link"
                    target="_blank" rel="noopener noreferrer">
                    <i class="feather icon-facebook fs-1"></i>
                </a>
            </div>
            <div class="col-4">
                <a href="https://www.instagram.com/plaquitas_cr/" class="nav-link" target="_blank"
                    rel="noopener noreferrer">
                    <i class="feather icon-instagram fs-1"></i>
                </a>
            </div>
        </div>
    </div>
</section>