<div class="container px-0">
    <div class=" bg-body-tertiary">
        <p-dataView styleClass="card rounded-3"
            #dv
            filterBy="productName,category,inventoryStatus"
            [value]="products" 
            title="Marketplace"
            [rows]="12" 
            dataKey="id"
            [rowsPerPageOptions]="[8,12,25,50]" 
            [paginator]="true"
            [layout]="'grid'"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}" 
            [loading]="loading">
            <p-header> 
                <div class="grid">
                    <div class="d-flex col-12 justify-content-between">
                        <button class="btn rounded-2 btn-transparent" (click)="showFilterInputs = !showFilterInputs" ><i class="feather icon-filter "></i>Filtrar</button>
                        <div *ngIf="!Media.IsMobile && showFilterInputs" class="col-12 md:col-3 lg:col-3">
                            <input type="search" class="w-100" pInputText placeholder="Buscar por nombre" (input)="Myfilter($any($event.target) .value)">
                        </div>
                        <div *ngIf="!Media.IsMobile && showFilterInputs" class="col-12 md:col-3 lg:col-3">
                            <p-dropdown  
                                styleClass="d-flex w-100" 
                                [options]="categoryList"
                                optionLabel="label"
                                [autoDisplayFirst]="false"
                                placeholder="FIltrar por categoria"
                                (onChange)="MyfilterCategory($any($event.value))"
                                [filter]="true" filterBy="label" [showClear]="true"
                                optionValue="value">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                                </ng-template>
                                <ng-template let-selectedItem pTemplate="selectedItem">
                                    {{selectedItem.label | translate}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div *ngIf="!Media.IsMobile && showFilterInputs" class="col-12 md:col-3 lg:col-3">
                            <p-dropdown  
                                styleClass="d-flex w-100" 
                                [options]="statusList"
                                optionLabel="label"
                                [autoDisplayFirst]="false"
                                placeholder="Filtrar por estado"
                                (onChange)="MyfilterCategory($any($event.value))"
                                [filter]="true" filterBy="label" [showClear]="true"
                                optionValue="value">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                                </ng-template>
                                <ng-template let-selectedItem pTemplate="selectedItem">
                                    {{selectedItem.label | translate}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                    </div>
                    <div *ngIf="Media.IsMobile && showFilterInputs" class="col-12">
                        <div class="col-12 md:col-4 lg:col-4">
                            <input type="search" class="w-100" pInputText placeholder="Buscar por nombre" (input)="Myfilter($any($event.target) .value)">
                        </div>
                        <div class="col-12 md:col-4 lg:col-4">
                            <p-dropdown  
                                styleClass="d-flex w-100" 
                                [options]="categoryList"
                                optionLabel="label"
                                [autoDisplayFirst]="false"
                                placeholder="FIltrar por categoria"
                                (onChange)="MyfilterCategory($any($event.value))"
                                [filter]="true" filterBy="label" [showClear]="true"
                                optionValue="value">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                                </ng-template>
                                <ng-template let-selectedItem pTemplate="selectedItem">
                                    {{selectedItem.label | translate}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="col-12 md:col-4 lg:col-4">
                            <p-dropdown  
                                styleClass="d-flex w-100" 
                                [options]="statusList"
                                optionLabel="label"
                                [autoDisplayFirst]="false" 
                                placeholder="FIltrar por estado"
                                (onChange)="MyfilterCategory($any($event.value))"
                                optionValue="value">
                                <ng-template let-option pTemplate="item">
                                    <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                                </ng-template>
                                <ng-template let-selectedItem pTemplate="selectedItem">
                                    {{selectedItem.label | translate}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </p-header>
            <ng-template let-product pTemplate="listItem">
                <div class="col-12">
                    <div class="flex flex-column xl:flex-row xl:align-items-start p-4" [ngClass]="{'gap-1': Media.IsMobile, 'gap-4': !Media.IsMobile}">
                        <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="product.images[0].imageURL" [alt]="product.productName" />
                        <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                            <div class="flex flex-column align-items-center sm:align-items-start gap-3">
                                <div class="font-bold text-900" [ngClass]="{'text-2xl': !Media.IsMobile, 'text-sm': Media.IsMobile}">{{ product.productName }}</div>
                                <p-rating *ngIf="!Media.IsMobile" [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating>
                                <div *ngIf="!Media.IsMobile" class="flex align-items-center gap-3">
                                    <span class="flex align-items-center gap-2">
                                        <i class="feather icon-tag"></i>
                                        <span class="font-semibold">{{ TranslateText(product.category) }}</span>
                                    </span>
                                    <p-tag [value]="TranslateText(product.inventoryStatus)" [severity]="getSeverity(product.inventoryStatus)"></p-tag>
                                </div>
                            </div>
                            <div *ngIf="!Media.IsMobile" class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                                <span class="font-semibold" [ngClass]="{'text-2xl': !Media.IsMobile, 'text-sm': Media.IsMobile}">{{ product.price | currency:'CRC':'symbol':'1.0-0' }}</span>
                                <a href="https://wa.me/+506{{removeRegex(product.phone)}}" class="btn btn-primary mx-3" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                    </svg> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template let-product pTemplate="gridItem">
                <div class="col-6 md:col-6 lg:col-3 px-2">
                    <div class="pb-2 border-0 surface-border surface-card rounded-2"> 
                        <span style="position: relative; top: 30px; left: 2px; padding: 6px;" class="rounded-2">
                            <button (click)="openMoreInfo(product)" class="btn btn-sm text-light font-semibold" style="font-size: 10px;" [ngClass]="{ 
                                'bg-info' : product.inventoryStatus == 'PROMOTION' ,
                                'bg-warning' : product.inventoryStatus == 'INSTOCK',
                                'bg-danger' : product.inventoryStatus == 'LOWSTOCK',
                                'bg-primary' : product.inventoryStatus == 'OUTOFSTOCK',
                                }" > <i class="feather icon-tag"></i>
                            <span>{{TranslateText(product.inventoryStatus)}}</span></button>  
                        </span>
                        <img (click)="openMoreInfo(product)" class="rounded-2 shadow" style="object-fit: cover; height: 200px; width: 100%;" src="{{product.images[0].imageURL}}" [alt]="product.productName" />
                        <div (click)="openMoreInfo(product)" style="cursor: pointer;" class="font-bold text-sm" [ngClass]="{'text-truncate': Media.IsMobile}"> <span class="font-semibold text-sm">{{ product.price | currency:'CRC':'symbol':'1.0-0' }}</span> | {{ product.productName }}</div>
                         
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="empty">
                <span *ngIf="!showHardReloadBtn"> {{ 'No data found' | translate }} </span>
                <span *ngIf="showHardReloadBtn"> {{'An error occurred in the process.' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                <img class="w-100" style="height: 400px; object-fit: cover;" src="assets/images/gifts/gift-version-{{randomNumber}}.gif" alt="Not Found Image">
            </ng-template>
        </p-dataView>
    </div>
  </div>

<p-sidebar styleClass="p-0 p-sidebar-custom" [blockScroll]="true" [(visible)]="sidebarVisible" [fullScreen]="true" (onHide)="closeSidebar()">
    <ng-template pTemplate="header">
        <button pButton type="button" icon="feather icon-maximize-2" (click)="displayBasic = true"></button> 
    </ng-template>
    <div *ngIf="productSelected">
        <app-market-item
            [displayBasic]="displayBasic"
            [productId]="productSelected._id">
        </app-market-item>
    </div>
</p-sidebar>
