<div class="p-3">
    <p class="fw-bolder">TÉRMINOS Y CONDICIONES para uso del sitio web <span
            class="fw-bolder">{{websiteName}}</span>:</p>
    <p>
        Los siguientes términos y condiciones constituyen un acuerdo legal entre <span
            class="fw-bolder">{{websiteName}}</span> y los USUARIOS de su sitio web y rigen el uso que usted
        le dé al sitio web y a cualquiera de los contenidos, características o funciones disponibles, que pudieran
        derivarse del mismo. <span class="fw-bolder">{{websiteName}}</span> podrá cambiar estos Términos y
        Condiciones en cualquier momento sin ninguna notificación, sólo publicando y actualizando dichos cambios en este
        sitio web. AL USAR EL SITIO WEB, USTED como USUARIO ACEPTA Y ESTÁ DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES EN
        LO QUE SE REFIERE A SU USO. Si usted no está de acuerdo con estos Términos y Condiciones, no puede tener acceso
        al mismo ni usar el sitio web de ninguna otra manera.
    </p>

    <p class="fw-bolder"> Derechos de propiedad del sitio web:</p>
    <p>
        Entre usted (USUARIO) y nosotros, <span class="fw-bolder">{{websiteName}}</span> es dueño único y
        exclusivo de todos los derechos, título e intereses de su sitio web, de todo el contenido (por ejemplo, audio,
        fotografías, ilustraciones, otros medios visuales, videos, copias, textos, software, títulos, códigos, datos y
        materiales del mismo); así como el aspecto, ambiente, diseño, organización, compilación de contenidos, códigos,
        datos y materiales, incluyendo pero no limitado a, cualesquiera derechos de autor, derechos de marca, derechos
        de patente, derechos de base de datos, derechos morales, derechos sui generis y otras propiedades intelectuales
        y derechos patrimoniales del mismo. El sitio web está protegido por las leyes y disposiciones de propiedad
        intelectual internacionales, por lo que el mismo no podrá ser asignado, sub-licenciado, concedido o transferido
        en virtud del presente Acuerdo.
    </p>

    <p class="fw-bolder">
        Acerca del uso del sitio web, su información y sus contenidos:</p>
    <p>
        El usuario del sitio web es el único responsable de tener a su disposición el hardware y software necesarios,
        así como el Dial-Up, DSL, Cable Módem o cualquier otro tipo para lograr su acceso a la Internet y para poder
        usar este sitio satisfactoriamente.

    </p>
    <p>
        El uso de este sitio web no le otorga al usuario la propiedad de ninguno de los contenidos, códigos, datos o
        materiales a los que pueda acceder en o a través del mismo, por lo tanto, no podrá almacenar, reproducir,
        copiar, editar o modificarlos. <span class="fw-bolder">{{websiteName}}</span> se reserva todos los
        derechos conforme a las leyes vigentes; cualquier uso indebido, no autorizado, publicación, distribución,
        alteración, explotación comercial o promocional del contenido de este sitio o cualquiera de los datos, códigos o
        materiales, o violación a este acuerdo; está estrictamente prohibido.
    </p>

    <p class="fw-bolder"> Marcas Comerciales:</p>
    <p>
        Las marcas comerciales, logos, marcas de servicios, marcas registradas (conjuntamente las "Marcas Comerciales")
        expuestas en este sitio web o en los contenidos disponibles a través del mismo, son marcas comerciales de <span
            class="fw-bolder">{{websiteName}}</span> registradas y no registradas y otras, y no pueden ser
        usadas con respecto a otros productos y/o servicios que no estén relacionados, asociados o patrocinados por sus
        poseedores de derechos y que puedan causar confusión a los clientes, o de alguna manera que denigre o
        desacredite a sus poseedores de derechos. Todas las marcas comerciales que no sean de <span
            class="fw-bolder">{{websiteName}}</span> y que aparezcan en, o a través, de este sitio web si las
        hubiera, son propiedad de sus respectivos dueños. El mal uso de las marcas comerciales expuestas en este sitio
        web, o a través de cualquiera de los servicios del mismo, está estrictamente prohibido. </p>

    <p class="fw-bolder"> Prohibiciones para el usuario del sitio web:</p>
    <p>
        El usuario garantiza y está de acuerdo en que, mientras use el sitio y los diversos servicios, características y
        funciones que se ofrecen en o a través del mismo, usted no: (a) personalizará a ninguna otra persona o entidad
        que no sea usted, ni desvirtuará su afiliación con alguna otra persona o entidad; (b) intentará ganar acceso no
        autorizado a otros sistemas de cómputo a través del sitio web. Usted no: (i) participará en navegar por la red,
        en "raspar (scraping) la pantalla", "raspar (scraping) la base de datos", en recolectar direcciones de correo
        electrónico, direcciones inalámbricas u otra información personal o de contactos, o cualquier otro medio
        automático de obtener listas de usuarios u otra información de o a través del sitio web o de los servicios
        ofrecidos en o a través del mismo, incluyendo, sin limitación, cualquier información que se encuentre en algún
        servidor o base de datos relacionada con el sitio web o los servicios ofrecidos en o a través del mismo. (ii)
        obtendrá o intentará obtener acceso no autorizado a los sistemas de cómputo, materiales o información por
        cualquier medio; (iii) usará el sitio web o los servicios puestos a su disposición de alguna manera que pudiera
        interrumpir, dañar, deshabilitar, sobrecargar o deteriorar el sitio web o dichos servicios, incluyendo, sin
        limitación, mandar mensajes masivos no solicitados o "inundar" servidores con solicitudes; (iv) usará el sitio
        web o los servicios o artículos del mismo en violación de la propiedad intelectual o de otros derechos legales o
        patrimoniales de <span class="fw-bolder">{{websiteName}}</span> o de algún tercero; ni (v) usará el
        sitio web o los servicios del sitio web en violación de cualquier ley aplicable. Usted se obliga además, a no
        intentar (o alentar o apoyar el intento de otro) a embaucar, destruir, decodificar, o de otro modo alterar o
        interferir con el sitio web o con los servicios, o con cualquier contenido del mismo, o hacer cualquier uso no
        autorizado del mismo. Usted se obliga a no usar el sitio web de alguna manera que pudiera interferir con el uso
        y disfrute de cualquier otra persona o de cualquiera de sus servicios. Usted no obtendrá ni intentará obtener
        algún material o información a través de cualquier medio que no haya sido estipulado o puesto a la disposición
        del público intencionalmente a través del sitio web
    </p>
    <p class="fw-bolder"> Compra de productos y Servicios:</p>
    <p>
        <span class="fw-bolder">{{websiteName}}</span>, pone sus productos y servicios a disposición de
        visitantes y registrados del sitio web. Si usted ordena cualquier producto, a través del presente documento
        usted acepta y garantiza que tiene 18 años de edad o más. Se obliga a pagar la totalidad de los precios de
        cualquier compra que realice ya sea con tarjeta de crédito/débito concurrente con su orden en línea o por otro
        medio de pago aceptable para <span class="fw-bolder">{{websiteName}}</span>.
    </p>
    <p>
        Los productos de <span class="fw-bolder">{{websiteName}}</span> se venden solamente a través de este
        sitio y de distribuidores autorizados en las siete provincias del país. Cualquier costo adicional por transporte
        y/o entrega, deberá ser asumido por el usuario del servicio. El usuario se obliga a pagar todos los impuestos
        aplicables. Si el pago no es recibido por <span class="fw-bolder">{{websiteName}}</span> de parte del
        emisor de su tarjeta de crédito o débito o de sus agentes, el usuario se obliga a pagar todas las cantidades
        debidas al momento del reclamo por nuestra parte. Algunos productos que usted compra y/o descarga en o a través
        del sitio web pueden estar sujetos a términos y condiciones adicionales que le serán presentados al momento de
        dicha compra o descarga.
    </p>

    <p class="fw-bolder"> Información personal del usuario:</p>
    <p>
        Al utilizar este sitio web y/o los servicios puestos a su disposición en o a través del mismo, se le solicitará
        que proporcione cierta información personalizada (dicha información denominada en lo sucesivo "Información
        personal del usuario"). Las políticas de privacidad, uso y recopilación de información de <span
            class="fw-bolder">{{websiteName}}</span>, se establecen en nuestra Política de Privacidad. Usted
        reconoce y acepta ser el único responsable de la exactitud del contenido de la “Información personal del
        usuario”.
    </p>

    <p class="fw-bolder"> Derechos de autor:</p>
    <p>
        Entre <span class="fw-bolder">{{websiteName}}</span> y los usuarios de este Sitio Web, el usuario
        reconoce que <span class="fw-bolder">{{websiteName}}</span> le brinda un código privado para
        introducir sus datos. El contenido incorporado en este Sitio es propiedad de los respectivos propietarios y está
        protegido por los derechos de autor y leyes de propiedad intelectual y sujeto a restricciones de uso, en virtud
        de dichas leyes.
    </p>


    <p class="fw-bolder"> Publicaciones en nuestro sitio web:</p>
    <p>
        <span class="fw-bolder">{{websiteName}}</span> puede, eventualmente, tener servicios de mensajería,
        chateo, tableros de noticias, blogs, otros foros y otros servicios disponibles en o a través del sitio web.
        Además de cualquier otra normatividad y regulación que podamos publicar con respecto a un servicio en
        particular, el usuario se obliga a no cargar, informar, transmitir, distribuir o de otra manera publicar a
        través del sitio web o de cualquier servicio o artículo puesto a la disposición en o a través del sitio web,
        cualquier material que (i) restrinja o inhiba a cualquier otro usuario de usar y disfrutar del Sitio Web o de
        los servicios del Sitio Web, (ii) sea fraudulento, ilegal, amenazante, abusivo, hostigante, calumnioso,
        difamatorio, obsceno, vulgar, ofensivo, pornográfico, profano, sexualmente explícito o indecente, (iii)
        constituya o aliente conductas que pudieran constituir una ofensa criminal, dar lugar a responsabilidad civil o
        de otro modo violar cualquier ley local, estatal, nacional o internacional, (iv) viole, plagie o infrinja los
        derechos de terceros incluyendo, sin limitación, derechos de autor, marcas comerciales, secretos comerciales,
        confidencialidad, contratos, patentes, derechos de privacidad o publicidad o cualquier otro derecho de
        propiedad, (v) contenga un virus, elemento de espionaje u otro componente dañino, (vi) contenga enlaces fijos,
        publicidad, cartas de cadenas o esquemas de pirámides de cualquier tipo, o (vii) constituya o contenga
        indicaciones de origen, endosos o declaraciones de hechos falsos o engañosos. Usted además se obliga a no
        personificar a cualquier otra persona o entidad, ya sea real o ficticia, incluyendo cualquier persona de <span
            class="fw-bolder">{{websiteName}}</span>. El usuario tampoco puede ofrecer comprar o vender algún
        producto o servicio en o a través de sus comentarios presentados en nuestros foros. El usuario del sitio web es
        responsable del contenido y de las consecuencias de cualquiera de sus actividades y publicaciones.
    </p>
    <p>
        Los comentarios presentados en un foro pueden ser registrados y almacenados en múltiples lugares, tanto en
        nuestro sitio web como en otra parte en Internet, los cuales pueden ser accesibles durante mucho tiempo y no se
        tiene control sobre quién los leerá eventualmente. Por lo tanto, es importante que como usuario tenga cuidado y
        sea selectivo acerca de la información personal que divulgue acerca de usted y de otros, y en especial, no debe
        divulgar información delicada, patrimonial o confidencial en sus comentarios en nuestros foros públicos.
    </p>

    <p class="fw-bolder"> Renuncia de garantías:</p>
    <p>
        EL SITIO WEB, INCLUYENDO, SIN LIMITACIÓN, TODOS LOS SERVICIOS, CONTENIDOS, CARACTERÍSTICAS, FUNCIONES Y
        MATERIALES PROPORCIONADOS A TRAVÉS DEL MISMO, SON PROPORCIONADOS "TAL COMO ESTÉN", "COMO SE PONEN A LA
        DISPOSICIÓN", SIN GARANTÍA DE NINGÚN TIPO, YA SEA EXPRESA O IMPLÍCITA, INCLUYENDO, SIN LIMITACIÓN, CUALQUIER
        GARANTÍA DE INFORMACIÓN, DATOS, SERVICIOS DE PROCESAMIENTO DE DATOS, ACCESO ILIMITADO O ININTERRUMPIDO,
        CUALQUIER GARANTÍA CON RESPECTO A LA DISPONIBILIDAD, INTERPRETABILIDAD, NO INTERPRETABILIDAD, EXACTITUD,
        PRECISIÓN, RECTITUD, PERFECCIÓN, CONCLUSIÓN, UTILIDAD, O CONTENIDO DE LA INFORMACIÓN, Y CUALQUIER GARANTÍA DE
        TÍTULO, NO-INFRACCIÓN, COMERCIABILIDAD O APTITUD PARA UN PROPÓSITO EN PARTICULAR, Y EN EL PRESENTE RENUNCIAMOS A
        CUALQUIERA Y A TODAS DICHAS GARANTÍAS, EXPRESAS E IMPLÍCITAS. NO GARANTIZAMOS QUE EL SITIO WEB O LOS SERVICIOS,
        CONTENIDOS, FUNCIONES O MATERIALES PROPORCIONADOS A TRAVÉS DEL SITIO WEB SERÉN OPORTUNOS, SEGUROS,
        ININTERRUMPIDOS O LIBRES DE ERROR, O QUE LOS DEFECTOS SERÉN CORREGIDOS. NO DAMOS GARANTÍA DE QUE EL SITIO WEB O
        DE QUE LOS SERVICIOS PROPORCIONADOS CUMPLAN CON LOS REQUERIMIENTOS DE LOS USUARIOS. NINGÚN CONSEJO, RESULTADOS O
        INFORMACIÓN, YA SEA ORAL O ESCRITA, QUE USTED OBTENGA DE NOSOTROS A TRAVÉS DEL SITIO WEB CREARÁ ALGUNA GARANTÍA
        QUE NO HAYA SIDO ESTABLECIDA EXPRESAMENTE EN EL PRESENTE ACUERDO. <span
            class="fw-bolder">{{websiteName}}</span> TAMPOCO ASUME NINGUNA RESPONSABILIDAD DE CUALQUIER DAÑO
        A, O DE CUALQUIER VIRUS QUE PUDIERA INFECTAR SU EQUIPO A CAUSA DE SU ACCESO A, USO DE, O NAVEGACIÓN EN EL SITIO
        WEB O POR DESCARGAR CUALQUIERA DE LOS MATERIALES, DATOS, TEXTOS, IMÁGENES, CONTENIDOS DE VIDEO O CONTENIDOS DE
        AUDIO DEL SITIO WEB. SI USTED NO ESTÁ SATISFECHO CON EL SITIO WEB, SU ÚNICA SOLUCIÓN ES DESCONTINUAR EL USO DEL
        SITIO WEB.

        TRATAMOS DE ASEGURAR QUE LA INFORMACIÓN PUBLICADA EN EL SITIO WEB SEA CORRECTA Y ACTUALIZADA. NOS RESERVAMOS EL
        DERECHO DE CAMBIAR O HACER CORRECCIONES A CUALQUIER INFORMACIÓN PROPORCIONADA EN EL SITIO WEB EN CUALQUIER
        MOMENTO Y SIN NINGÚN AVISO PREVIO. <span class="fw-bolder">{{websiteName}}</span> NI AVALA NI ES
        RESPONSABLE DE LA EXACTITUD O VERACIDAD DE CUALQUIER OPINIÓN, CONSEJO O DECLARACIÓN EN EL SITIO WEB, NI DE
        CUALQUIER PUBLICACIÓN OFENSIVA, DIFAMATORIA, OBSCENA, INDECENTE, ILEGAL O VIOLATORIA HECHA EN EL MISMO POR
        CUALQUIER PERSONA A NO SER UN EMPLEADO PORTAVOZ AUTORIZADO DE <span
            class="fw-bolder">{{websiteName}}</span> EN SU CARÉCTER OFICIAL.
    </p>

    <p class="fw-bolder"> Límite de responsabilidad:</p>
    <p>
        EN NINGÚN CASO, INCLUYENDO PERO NO LIMITADO A NEGLIGENCIA, <span
            class="fw-bolder">{{websiteName}}</span>, O CUALQUIERA DE SUS DIRECTORES, FUNCIONARIOS, EMPLEADOS,
        AGENTES O PROVEEDORES; SERÁ RESPONSABLE DE CUALQUIER DAÑO DIRECTO, INDIRECTO, ESPECIAL, INCIDENTAL, CONSECUENTE,
        EJEMPLAR O PUNITIVO COMO RESULTADO DE, O DIRECTA O INDIRECTAMENTE RELACIONADO CON, EL USO DE, O LA INCAPACIDAD
        DE USAR EL SITIO WEB O LOS CONTENIDOS, CARACTERÍSTICAS, MATERIALES Y FUNCIONES RELACIONADOS CON EL MISMO, SU
        PROVISIÓN DE INFORMACIÓN VÍA EL SITIO WEB, NEGOCIOS PERDIDOS O VENTAS PERDIDAS, AÚN CUANDO DICHA ENTIDAD
        PROTEGIDA HAYA SIDO ASESORADA SOBRE LA POSIBILIDAD DE DICHOS DAÑOS.
    </p>
    <p>
        <span class="fw-bolder">{{websiteName}}</span> declina toda responsabilidad en caso de que el acceso o
        las visitas a su sitio web se vean imposibilitadas debido a una interrupción o defectuosa prestación de
        suministro eléctrico, telefónico o de proveedores de telecomunicaciones, o en e caso de producirse conflictos
        sociales y otros supuestos de fuerza mayor o cualquier actuación de un tercero. Se declina igualmente toda
        responsabilidad derivada de los datos que pudiera recibir el usuario en sus medios informáticos o telemáticos a
        consecuencia de la producción de cualquiera de las circunstancias antes expuestas.
    </p>

    <p class="fw-bolder"> Indemnización:</p>
    <p>
        Si el usuario del sitio web incumple estos Términos y Condiciones, incluyendo pero no limitado a cualquier
        violación de los derechos de autor o de propiedad intelectual, ya sea de su parte o de terceros, se obligará a
        indemnizar y a excluir a <span class="fw-bolder">{{websiteName}}</span>, a sus directores,
        funcionarios, empleados y agentes; de cualquier demanda, responsabilidad, costos y gastos, de cualquier
        naturaleza, incluyendo honorarios de abogados, en que incurriera como resultado del mal uso del sitio web.
    </p>
    <p>
        Este acuerdo está regido por las leyes costarricenses. Para cualquier reclamo, acción o disputa con <span
            class="fw-bolder">{{websiteName}}</span>, a partir del uso de este sitio web, tendrá como
        jurisdicción exclusiva a San José, Costa Rica. Para cualquier comentario u observación, podrá escribir a <a
            href="soporte@localpetsandfamily.com">soporte@localpetsandfamily.com</a>
    </p>
    <p class="fw-bolder"> ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES:</p>
    <p>
        El presente Acuerdo permanecerá vigente hasta que <span class="fw-bolder">{{websiteName}}</span>
        denuncie lo contrario. Los cambios en los Términos y Condiciones serán efectivos cuando se publiquen.
    </p>
    <p>
        Usted como usuario acepta que es una persona mayor de 18 años de edad y declara que ha leído y entendido la
        información que se describe en el presente acuerdo de Términos y Condiciones. Al aceptar este acuerdo usted
        estará vinculado con lo que aquí se declara a nombre del “Usuario”, de lo contrario, no ingrese a este sitio.
    </p>
</div>