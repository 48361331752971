<div class="register">
    <div class="auth-content mx-3">
        <div class="card register-card">
            <div class="goHome">
                <a class="text-dim text-start" [routerLink]="'/home'">
                    <i class="feather icon-home fs-5"></i>
                </a>
            </div>
            <div class="card-header text-center">
                <img src="assets/images/plaquitascr.png" alt="Plaquitas CR Logo registro principal" class="img-fluid" height="150px" width="150px">
            </div>
            <div class="card-body">
                <app-general-form 
                    [primaryId]="primaryId"
                    [formIdType]="formIdType" 
                    [isEditProfile]="false"
                ></app-general-form>

                <div class="col-12 mt-4 d-flex justify-content-between">
                    <!-- <a [routerLink]="['/forgotPassword']" class="text-sm ml-auto text-decoration-none text-dim">{{'Forgot password' | translate}}</a><br> -->
                    <a [routerLink]="['/login']" class="text-sm ml-auto text-decoration-none text-dim">{{'Sign in' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>