<div class="container">
    <button class="btn btn-secondary m-4" (click)="backToHome()">  
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-left"><polyline points="9 14 4 9 9 4"/><path d="M20 20v-7a4 4 0 0 0-4-4H4"/></svg>
    </button>
    <div *ngIf="payloadData" class="grid">
        <div class="col-12 md:col-8 lg:col-8">
            <p-galleria [value]="payloadData.images" [showItemNavigators]="true" [showThumbnails]="false" [showIndicators]="true" [showIndicatorsOnItem]="true" [responsiveOptions]="responsiveOptions" [circular]="true" [numVisible]="5" [containerStyle]="{ 'max-width': 'auto' }">
                <ng-template pTemplate="item" let-item>
                    <img [ngStyle]="{'width': Media.IsMobile ? '100%' : 'auto'}" [src]="item.imageURL" style="display: block; height: 22rem; object-fit: cover;" />
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                        <img height="40px" width="40px" [src]="item.imageURL" style="display: block;" />
                    </div>
                </ng-template>
            </p-galleria>        
    
            <p-galleria [value]="payloadData.images" [(visible)]="displayBasic" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '50%'}" [numVisible]="9"
                [circular]="true" [fullScreen]="true" [showItemNavigators]="true">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.imageURL" style="width: 100%; display: block;"/>
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                        <img height="40px" width="40px" [src]="item.imageURL" style="display: block;"/>
                    </div>
                </ng-template>
            </p-galleria>
        </div>
        <div class="col-12 md:col-4 lg:col-4 card"> 
            <div class="grid nested-grid">
                <div class="col-12">
                    <div class="grid p-3">
                        <div class="col-12">
                            <span class="flex align-items-center gap-2">
                                <i class="feather icon-tag"></i>
                                <span class="font-semibold">{{ TranslateText(payloadData.category) }}</span>
                                <button type="button" class="btn" (click)="copyURL(urlbyPass)"> <i class="feather icon-copy"></i> </button>
                            </span>
                            <div class="text-start p-3 border-round-sm">
                                <div class="text-2xl text-900 font-bold">{{ payloadData.productName }} </div>
                                <div class="text-2xl text-900 font-bold">{{ payloadData.price | currency:'CRC':'symbol':'1.0-0' }} 
                                <button class="btn btn-sm font-semibold" style="font-size: 10px;" [ngClass]="{ 
                                    'bg-info' : payloadData.inventoryStatus == 'PROMOTION' ,
                                    'bg-warning' : payloadData.inventoryStatus == 'INSTOCK',
                                    'bg-danger text-light' : payloadData.inventoryStatus == 'LOWSTOCK',
                                    'bg-primary' : payloadData.inventoryStatus == 'OUTOFSTOCK',
                                    }" > <i class="feather icon-tag"></i>
                                <span>{{TranslateText(payloadData.inventoryStatus)}}</span></button> </div>
        
                                <div class="text-sm text-900">Publicado {{dayPublished}}</div>
                                <div class="text-sm text-900">Última Actualización {{dayUpdated}}</div>
                                <p-rating [ngModel]="payloadData.rating" [readonly]="true" [cancel]="false"></p-rating>
                            </div>
                        </div>
                        <div class="col-12">
                            <a href="{{textMessageLink}}" class="btn btn-primary p-3" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                </svg> 
                                Enviar Mensaje al vendedor
                            </a>
                        </div>
                        <div class="col-12">
                            <div class="p-3 border-round-sm">
                                <div class="text-2xl text-900 font-bold">Descripción del producto</div>
                                <div class="text-sm text-900" style="max-height: 400px; overflow-y: auto;" [innerHTML]="byPassSecurity(payloadData.description)"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>